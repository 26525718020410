<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
	name: 'App',
	data() {
		return {};
	},
}
</script>

<style>
html,body{margin: 0px;padding: 0px;}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-top: 0px;
}
.el-header {
	box-shadow: 0px 0px 5px #B3C0D1;
	border-bottom: #eaeaea solid 1px;
	color: #333;
	padding: 5px 10px;
	position: fixed;
	left: 240px;
	right: 0px;
	background-color: #FFFFFF;
	z-index: 1;
}
  
.el-aside {
	background-color: #ae1c27;
	color: #333;
	min-height: 400px;
	height: 100%;
	text-align: center;
	box-sizing: border-box;
	position: fixed;
}

.el-main {
	background-color: #f4f4f4;
	color: #333;
	overflow-y: auto;
	position: fixed;
	top: 60px;
	bottom: 0px;
	left: 240px;
	right: 0px;
}
.card {
	border: 0;
	width: 95%;
	height: auto;
	margin: 0px auto;
	border-radius: 12px;
	margin-bottom: 1.875rem;
	padding: 20px;
	background: #fff;
	box-shadow: 0 10px 30px 0 rgb(24,28,33, 0.05);
}
.con {
	text-align: center;
	background: #fff;
	display: flex;
	flex-direction: row;
}
.con .con_title{
	display: flex;
	flex-direction: row;
	width: 80%;
}
.con .con_title .con_line{
	width: 5px;
	height: 25px;
	background: #be2f2e;
	margin: 0px;
}
.con .con_title .con_text{
	font-size: 18px;
	font-weight: 600;
	margin-left: 10px;
}
.el-form-item{margin-bottom: 15px;}
.el-form-item__label{color: #000000;}
.el-textarea__inner{height: 120px !important;}
.el-select{width: 100%;}
.el-table{color: #191919 !important;}
.el-dialog__body{padding: 15px 20px;}
</style>
