import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index';
import axios from 'axios';
import VueAxios from 'vue-axios';

const baseUrl = 'https://server.ifufan.com:3002';
Vue.prototype.$ApiConfig = { baseUrl };
axios.defaults.baseURL = baseUrl;
Vue.prototype.$axios = axios;
Vue.use(VueAxios).use(axios).use(ElementUI);

import App from './App.vue'

Vue.config.productionTip = false;

new Vue({
	el: "#app",
	router,
	render: h => h(App)
})
