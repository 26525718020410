import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: () => import('../pages/index'),
	},
	{
		path: '/login',
		component: () => import('../pages/login'),
	},
	{
		path: '/yuyue',
		component: () => import('../pages/yuyue'),
	}
];

const router = new VueRouter({
	base: './',
	routes,
	mode: "hash"
})

router.beforeEach((to, from, next) => {
	let isAuthenticated = window.sessionStorage.getItem('user');
	if(!isAuthenticated && to.path !== '/login'){
		next({ path: '/login' });
	}else{
		next();
	}
})

export default router;